import { Controller } from '@hotwired/stimulus'

declare global {
  interface JQuery {
    DataTable: any,
  }
}

class DatatableController extends Controller<HTMLTableElement> {
  private boundFilterByStatus: (event: Event) => void

  static values = {
    options: Object,
  }

  declare entriesListTarget: HTMLTableElement
  declare optionsValue: Object
  declare table: JQuery<HTMLTableElement>

  connect() {
    this.datatableInit()

    this.boundFilterByStatus = this.filterByStatus.bind(this)
    this.statusFilter?.addEventListener('change', this.boundFilterByStatus)
  }


  datatableInit() {
    // prevent double initialization
    if (this.element.classList.contains('dataTable')) return;

    this.table = $(`#${this.element.id}`).DataTable(this.optionsValue)
  }

  filterByStatus() {
    let currentSearch = this.table.search()
    currentSearch = currentSearch.replace(/\w+:\w+/g, '')
    currentSearch = this.statusFilter?.value + ' ' + currentSearch
    currentSearch = currentSearch.replace(/\s{2,}/g, ' ').trim()
    this.table.search(currentSearch).draw()
    this.flash()
  }

  flash() {
    if (this.searchInput?.classList.contains('flashMe')) {
      this.searchInput?.classList.remove('flashMe')
      setTimeout(this.flash, 1, this.searchInput)
      return
    }
    this.searchInput?.classList.add('flashMe') 
  }

  disconnect(): void {
    this.statusFilter?.removeEventListener('change', this.boundFilterByStatus)
  }

  get searchInput() {
    return document.querySelector('.dataTables_filter input')
  }

  get statusFilter() {
    return document.querySelector<HTMLSelectElement>('#assignment_status')
  }
}

export default DatatableController
