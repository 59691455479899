import { Controller } from "@hotwired/stimulus";

class CurriculumController extends Controller {
  static targets = ['csvUpload']

  declare dialogOpen: boolean
  declare dialog: JQuery<HTMLDivElement>
  declare beginNextPhase: JQuery<HTMLButtonElement>
  declare csvUploadTarget: HTMLInputElement

  connect() {
    this.dialogOpen = false
    this.beginNextPhase = $(`#begin_next_phase`)
  }

  submit(event){
    if (this.dialogOpen) return true

    event.preventDefault()

    this.dialog = $(`#next_phase_confirmation_dialog`).dialog({
      modal: true,
      buttons: [
        {
          text: 'Yes I\'m sure.  Let fly, emails!',
          class: 'button success',
          click: () => {
            this.dialogOpen = true
            this.beginNextPhase.submit()
            this.dialog.dialog('close')
          }
        },
        {
          text: 'No, wait a minute!',
          class: 'button danger',
          click: () => this.dialog.dialog('close')
        }
      ],
      close: () => this.dialogOpen = false
    })

    this.dialogOpen = true
    return false
  }

  uploadCsv(event){
    if (this.csvUploadTarget.files && this.csvUploadTarget.files.length > 0) {
      this.csvUploadTarget.form?.submit()
    }
  }

  deleteEditor(event: Event) {
    event.preventDefault()
    const button = event.currentTarget as HTMLButtonElement
    const rowId = button.dataset.rowId
    if (rowId) {
      const row = document.getElementById(rowId)
      row?.remove()
    }
  }
}

export default CurriculumController
