import moment from 'moment-timezone'
import 'jquery-datetimepicker'
import $ from 'jquery'

export function initDatetimepicker(selector = "[data-datetimepicker]") {
  // Set date formatter
  $.datetimepicker.setDateFormatter({
    parseDate: function (date, format) {
      var d = moment(date, format);
      return d.isValid() ? d.toDate() : false;
    },
    formatDate: function (date, format) {
      return moment(date).format(format);
    },
  });

  // Define default configuration
  const defaultConfig = {
    format: "MM/DD/YYYY h:mm a",
    formatDate: "MM/DD/YYYY",
    formatTime: "h:mm a",
    scrollMonth: false,
    step: 30,
    timezone: "America/Chicago"
  };

  // Initialize datetimepicker for all matching elements
  $(selector).each(function(i, item) {
    const $item = $(item);
    const $input = $($item.data('datetimepicker'));
    const options = $item.data('datetimepicker-options');

    item.config = $.extend({}, defaultConfig, options);
    $item.datetimepicker(item.config);

    if (!$input.length) return;

    $item
      .off('change.datetimepicker')
      .on('change.datetimepicker', function(event) {
        const time = moment(this.value);
        if (time.isValid()) {
          $input.val(time.toISOString());
        } else {
          $input.val('');
        }
      });
  });
}
