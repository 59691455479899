import { Controller } from "@hotwired/stimulus"

declare global {
  interface Window {
    _inputs_dirty: boolean,
    _form_being_submitted: boolean
  }
}

class EntryFormController extends Controller {
  static targets = ['versionPicker']
  
  private readonly PREVIEW_MESSAGE = 'You need to save your changes before the preview will work.'
  declare readonly versionPickerTarget: HTMLSelectElement

  connect(): void {
    this.initializeGlobalState()
    this.setupEventListeners()
    this.initializeVersions()
  }

  private initializeGlobalState(): void {
    window._inputs_dirty = false
    window._form_being_submitted = false
    window.editors = window.editors || {}
  }

  private setupEventListeners(): void {
    this.versionPickerTarget.addEventListener('change', this.handleVersionChange.bind(this))
    this.allInputs.forEach(input => input.addEventListener('change', this.handleInputChange.bind(this)))
    
    const previewLink = document.querySelector('#preview_link')
    previewLink?.addEventListener('click', this.handlePreviewClick.bind(this))
    
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
  }

  private initializeVersions(): void {
    this.hideAllVersions()
    this.chooseVersion(this.versionPickerTarget.value)
  }

  private handleVersionChange = (): void => {
    this.chooseVersion(this.versionPickerTarget.value)
  }

  private handleInputChange = (): void => {
    window._inputs_dirty = true
  }

  private handlePreviewClick = (e: Event): boolean => {
    if (this.isDirty()) {
      e.preventDefault()
      alert(this.PREVIEW_MESSAGE)
      return false
    }
    return true
  }

  private handleSubmit = (): void => {
    window._form_being_submitted = true
  }

  private handleBeforeUnload = (e: BeforeUnloadEvent): boolean => {
    if (this.isDirty() && !window._form_being_submitted) {
      e.preventDefault()
    }

    return true
  }

  chooseVersion(v: string): void {
    this.hideAllVersions()

    const jsVersions = document.querySelectorAll<HTMLDivElement>(`.js-version-${v}`)
    jsVersions.forEach(v => {
      v.style.display = 'block'
    })
  }

  showFirstVersions(): void {
    this.versions[0].style.display = 'block'
  }

  hideAllVersions(): void {
    this.versions.forEach(v => {
      v.style.display = 'none'
    })
  }

  isDirty(): boolean {
    for (const editor of Object.values(window.editors)) {
      if (editor.isDirty) {
        return true
      }
    }
    return window._inputs_dirty
  }

  get versions(): NodeListOf<HTMLDivElement> {
    return document.querySelectorAll('[class*="js-version"')
  }

  get allInputs(): NodeListOf<HTMLInputElement> {
    return document.querySelectorAll('input[type="text"]')
  }
}

export default EntryFormController;