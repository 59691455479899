import '@hotwired/turbo-rails'
import '../../javascript/controllers'

import { initDatetimepicker } from '../application/datetimepicker'
import { initSelectize } from '../application/selectize';
import { initSortable } from '../application/sortable-ui';

declare global {
  interface Window {
    WCC: {
      tessaInit: () => void
    }
  }
}

const initTessa = window.WCC.tessaInit

document.addEventListener('turbo:load', () => {
  initDatetimepicker()
  initSelectize()
  initSortable()
  
  try {
    initTessa()
  } catch (e) {
    if (e.message == 'Dropzone already attached.') {
      console.error('Dropzone already initialized')
    } else {
      throw e
    }
  }
});