import { Controller } from "@hotwired/stimulus"

declare global {
  interface Window {
    entryForm: any
  }
}

class EntryTagsController extends Controller {
  static targets = ['tagsList', 'deletedList', 'addTag', 'tag']

  declare readonly addTagTarget: HTMLAnchorElement
  declare readonly tagTargets: HTMLInputElement[]
  declare readonly tagsListTarget: HTMLDivElement
  declare readonly deletedListTarget: HTMLDivElement

  private tagElements: Map<string, HTMLSpanElement> = new Map()

  connect() {
    window.entryForm = window.entryForm || {}
    this.initializeTags()
  }

  private createTagElement(value: string): HTMLSpanElement {
    const tag = document.createElement('span')
    tag.classList.add('label', 'info')
    tag.textContent = value
    this.tagElements.set(value, tag)
    return tag
  }

  private createHiddenInput(value: string): HTMLInputElement {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'entry_form[tags][]')
    input.value = value
    return input
  }

  initializeTags() {
    this.tagTargets.forEach((tagInput) => {
      const tagElement = this.createTagElement(tagInput.value)
      this.tagsListTarget.appendChild(tagElement)
      tagElement.addEventListener('click', () => this.toggleDelete(tagElement, tagInput))
    })
  }

  add(event: Event) {
    event.preventDefault()
    
    const button = event.target as HTMLAnchorElement
    const input = document.createElement('input')
    input.setAttribute('list', 'all-tags')
    button.replaceWith(input)

    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        input.blur()
      }
    })

    input.addEventListener('blur', (e) => this.onBlur(button, e))
    input.focus()
  }

  private onBlur(button: HTMLAnchorElement, event: FocusEvent) {
    const input = event.currentTarget as HTMLInputElement
    const value = input.value.trim()

    input.replaceWith(button)

    if (!value || this.tagExists(value)) {
      return
    }

    const hiddenInput = this.createHiddenInput(value)
    const tagElement = this.createTagElement(value)
    
    this.tagsListTarget.appendChild(hiddenInput)
    this.tagsListTarget.appendChild(tagElement)
    
    tagElement.addEventListener('click', () => this.toggleDelete(tagElement, hiddenInput))
  }

  private tagExists(value: string): boolean {
    return this.tagTargets.some(tag => tag.value === value)
  }

  private toggleDelete(tagElement: HTMLSpanElement, input: HTMLInputElement) {
    const isDeleted = tagElement.hasAttribute('data-delete')
    
    if (isDeleted) {
      this.undeleteTag(tagElement, input)
    } else {
      this.deleteTag(tagElement, input)
    }
  }

  private deleteTag(tagElement: HTMLSpanElement, input: HTMLInputElement) {
    input.setAttribute('name', 'entry_form[deleted_tags][]')
    this.deletedListTarget.appendChild(tagElement)
    tagElement.dataset.delete = 'true'
    tagElement.classList.add('danger')
  }

  private undeleteTag(tagElement: HTMLSpanElement, input: HTMLInputElement) {
    input.setAttribute('name', 'entry_form[tags][]')
    this.tagsListTarget.appendChild(tagElement)
    delete tagElement.dataset.delete
    tagElement.classList.remove('danger')
  }
}

export default EntryTagsController
