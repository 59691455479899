function fixWidthHelper(e, ui) {
  ui.children().each(function() {
    $(this).width($(this).width());
  });
  return ui;
}

const initSortable = () =>  {  
  $(":ui-sortable").sortable('destroy');

  $('[data-orderable]').each(function(i, el) {
    var $el = $(el);
    var options = $el.data('orderable');

    $el.sortable({
      containment: options.containment || "parent",
      handle: options.handle || ".handle",
      helper: fixWidthHelper,
      update: function(e, ui) {
        $.ajax({
          url: options.url,
          method: 'PUT',
          data: $el.sortable('serialize')
        });
      }
    });
  });
};

export { initSortable };