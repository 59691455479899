import { Controller } from "@hotwired/stimulus"

interface EditorConfig {
  licenseKey: string
  wproofreader: {
    serviceId: string
    srcUrl: string
  }
  wordCount: {
    displayCharacters: boolean
  }
}

interface EditorOptions {
  readOnly: boolean
  height: string
  toolbar: string
}

declare global {
  var ClassicEditor: any
  var window: Window & typeof globalThis
  var editors: Record<string, any>
}

/**
 * CKEditor Stimulus Controller
 * Handles initialization and management of CKEditor instances
 */
class CKEditorController extends Controller {
  static targets = ['input']
  static values = {
    options: Object
  }

  declare inputTarget: HTMLInputElement
  declare optionsValue: EditorOptions
  private editor: any = null

  connect() {
    this.initializeCKEditor()
  }

  disconnect() {
    this.cleanup()
  }

  private async initializeCKEditor() {
    try {
      this.addEditorStyles()
      this.createWordCountSpan()

      this.editor = await ClassicEditor.create(
        this.inputTarget,
        this.getEditorConfig()
      )

      this.setupEditor() 
    } catch (error) {
      console.error('CKEditor initialization failed:', error)
    }
  }

  private getEditorConfig(): EditorConfig {
    return {
      licenseKey: '',
      wproofreader: {
        serviceId: 'UrwWCWU0rEZyMjM',
        srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
      },
      wordCount: {
        displayCharacters: false,
      }
    }
  }

  private setupEditor() {
    const toolbar = this.editor.ui.view.toolbar.element

    this.editor.isDirty = false

    if (this.optionsValue.readOnly) {
      this.editor.enableReadOnlyMode(this.inputTarget.id)
    }

    if (this.optionsValue.toolbar === 'none') {
      toolbar.style.display = 'none'
    }

    this.setupEditorEvents()
    this.setupWordCount()
    this.registerEditorInstance()
  }

  private setupEditorEvents() {
    this.editor.model.document.on('change:data', () => {
      this.editor.isDirty = true
    })
  }

  private setupWordCount() {
    const wordCountPlugin = this.editor.plugins.get('WordCount')
    const wordCountWrapper = document.querySelector(`#${this.inputTarget.id}_word_count`)
    wordCountWrapper?.appendChild(wordCountPlugin.wordCountContainer)
  }

  private registerEditorInstance() {
    window.editors = window.editors || {}
    window.editors[this.inputTarget.id] = this.editor

    this.editor.on('destroy', () => {
      delete window.editors[this.inputTarget.id]
    })
  }

  private addEditorStyles() {
    const id = this.inputTarget.id

    const styles = `
      .${id} .ck-content {
        height: ${this.optionsValue.height}px;
        resize: vertical;
      }    

      ${this.optionsValue.readOnly ? `
        .${id} .ck-toolbar {
          background-color: #f8f8f8;
        }

        .${id} .ck.ck-editor__main > .ck-editor__editable {
          background-color: #eee;
        }
        .${id} .ck-word-count {
          background-color: #f8f8f8;
        }
      ` : ''}

      .${id} .ck-word-count {
        border-left: 1px solid #ccced1;
        border-right: 1px solid #ccced1;
        border-bottom: 1px solid #ccced1;
        padding: 5px;
        text-align: right;
      }
    `

    const styleElement = document.createElement('style')
    styleElement.textContent = styles
    styleElement.dataset.editorId = id
    document.head.appendChild(styleElement)
  }

  private createWordCountSpan() {
    const span = document.createElement('span')
    span.id = `${this.inputTarget.id}_word_count`
    this.inputTarget.insertAdjacentElement('afterend', span)
  }

  private cleanup() {
    if (this.editor) {
      const styleElement = document.querySelector(`style[data-editor-id="${this.inputTarget.id}"]`)
      styleElement?.remove()

      this.editor.destroy()
        .catch((error: Error) => console.error('Editor cleanup failed:', error))
    }
  }
}

export default CKEditorController
