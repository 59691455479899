const initSelectize = () => {
  $('body.enable-selectize select:not(.no-selectize):not(.selectized)').each(function(i, item) {
    $(item).selectize({
      hideSelected: false
    });
  });

  $('[data-string-array-selectize]').each(function(i, item) {
    $(item).selectize({
      delimiter: ',',
      persist: false,
      create: function(input) {
        return {
          value: input,
          text: input
        }
      },
      onInitialize: updateHiddenStringArrayFields,
      onChange: updateHiddenStringArrayFields
    });
  });
}

function updateHiddenStringArrayFields(values) {
  var selectize = this;
  var hiddenFieldBaseName = selectize.$input.data('string-array-selectize');
  values = (values || "").split(",");

  selectize.$wrapper.find('[type="hidden"][data-wcc-string-array]').remove();

  values.forEach(function(value) {
    $('<input type="hidden" data-wcc-string-array />')
      .attr('name', hiddenFieldBaseName + '[]')
      .val(value)
      .appendTo(selectize.$wrapper);
  });

  return false;
}

export { initSelectize }