import { Controller } from "@hotwired/stimulus"

class MemoryVerseController extends Controller {
  static targets = ["reference", "translation", "verseHtml"]

  declare referenceTarget: HTMLInputElement
  declare translationTarget: HTMLInputElement

  async referenceChanged() {
    const reference = this.referenceTarget.value
    const translation = this.translationTarget.value

    if (window.editors && window.editors['memory_verse_form_verse_html']) {
      try {
        const response = await fetch(
          `/api/scripture/html?query=${encodeURIComponent(reference)}&version=${encodeURIComponent(translation)}`
        )

        if (response.ok) {
          const text = await response.text()
          window.editors['memory_verse_form_verse_html'].data.set(text)
        } else {
          console.error(`Error fetching scripture HTML from server: ${response.status}`)
        }
      } catch (err) {
        console.error(`Error fetching scripture HTML from server: ${err}`)
      }
    }
  }
}

export default MemoryVerseController
